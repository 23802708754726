<template>
  <v-container>
    <v-row justify="center" >
      <v-col cols="12">
        <v-card>
          <v-card-title>About</v-card-title>
          <v-card-text>
            DATTs (Disease-Associated Therapeutic Targets) is a collection of dispersed information on therapeutic target molecules for various human diseases and their detailed actions on diseases. A unique feature is that inhibitory and activatory targets are distinguished for each disease. The resource is provided with format in machine-readable. The knowledge of the detailed regulations of therapeutic target molecules for various diseases should have many applications in medical and pharmaceutical research. For example, the resource is expected to be useful in the search for therapeutic target proteins of diseases and in the prediction of applicable diseases of drug candidate compounds.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" >
      <v-col cols="12">
        <v-card>
          <v-card-title> References </v-card-title>
          <v-card-text>
            <v-row v-for="(ref, i) in references" :key="i">
              <v-col cols="1"> {{ ref.index }}. </v-col>
              <v-col cols="7">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ ref.author }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                        <a v-if="ref.url" :href="ref.url">{{ ref.title }}</a>
                        <span v-else>{{ ref.title }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ ref.date }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HomeSearch',

  data: () => ({
    references: [
    {
      index: 1,
      author: 'Namba, S., Iwata, M., Nureki, S., Otani, N., and Yamanishi, Y.',
      title: 'Therapeutic target prediction for orphan diseases integrating genome-wide and transcriptome-wide association studies',
      url: '',
      date: 'Nature Communications, to appear, 2025.'
    },
    {
      index: 2,
      author: 'Namba, S., Li, C., Otani, N., and Yamanishi, Y.',
      title: 'SSL-VQ: Vector-quantized variational autoencoders for semi-supervised prediction of therapeutic targets across diverse diseases',
      url: 'https://pubmed.ncbi.nlm.nih.gov/39880378/',
      date: 'Bioinformatics, 41(2), btaf039, 2025.'
    },
    {
      index: 3,
      author: 'Namba, S., Iwata, M., and Yamanishi, Y.',
      title: 'From drug repositioning to target repositioning: prediction of therapeutic targets using genetically perturbed transcriptomic signatures',
      url: 'https://pubmed.ncbi.nlm.nih.gov/35758779/',
      date: 'Bioinformatics, 38:i68-i76, 2022.'
    },
    {
      index: 4,
      author: 'Sawada, R., Iwata, M., Tabei, Y., Yamato, H., and Yamanishi, Y.',
      title: 'Predicting inhibitory and activatory drug targets by chemically and genetically perturbed transcriptome signatures',
      url: 'https://pubmed.ncbi.nlm.nih.gov/29317676/',
      date: 'Scientific Reports, 8:156, 2018.'
    },
    {
      index: 5,
      author: 'Sawada, R., Iwata, H., Mizutani, S., and Yamanishi, Y.',
      title: 'Target-based drug repositioning using large-scale chemical-protein interactome data',
      url: 'https://pubmed.ncbi.nlm.nih.gov/26580494/',
      date: 'Journal of Chemical Information and Modeling, 55(12), 2717–2730, 2015.'
    },
    {
      index: 6,
      author: 'Yamanishi, Y., Kotera, M., Moriya, Y., Sawada, R., Kanehisa, M., and Goto, S.',
      title: 'DINIES: drug-target interaction network inference engine based on supervised analysis',
      url: 'https://pubmed.ncbi.nlm.nih.gov/24838565/',
      date: 'Nucleic Acids Research, 42, W39-W45, 2014.'
    }
   ]
  })
}
</script>
